$primary-color: rgb(0, 59, 110);
$accend-color: rgb(197, 26, 27);
$text-colors: (
  "white": white,
  "light": lighten(black, 40%),
  "dark": black,
  "black": black,
);

$element-colors: (
  "light": #f2f2f2,
  "base": #dcdddf,
  "dark": #d9d9d9,
);

// Hoping for https://www.w3.org/TR/mediaqueries-5/#custom-mq soon
$breakpoints: (
  "phone": 768px,
  "tablet": 1024px,
  "desktop": 1280px,
);

// $theme should not be used if possible.
// use css variable instead, So it can be customized from projects
$theme: (
  "primary": $primary-color,
  "accend": $accend-color,
  "company": $primary-color,
  "warning": #efef03,
  "danger": red,
  "success": #2aaa13,
  "info": 'lightblue',
  "text": $text-colors,
  "element": $element-colors,
  "breakpoints": $breakpoints,
);

:root {
  --primary-color: #{$primary-color};
  --accend-color: #{$accend-color};
  --success-color: #2aaa13;
  --danger-color: red;
  --text-color: #353535;
  --default-font-size: 12px;
  --bold-font-weight: 800;
  --font-family: "Open sans", sans-serif;
  --max-content-width: 1200px;
  --content-margin: 20px;
  --element-colors-light: #{map-get($element-colors, "light")};
  --element-colors-base: #{map-get($element-colors, "base")};
  --element-colors-dark: #{map-get($element-colors, "dark")};
  --article-image-border-radius: 8px;
}
