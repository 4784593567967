@import "theme.scss";
@import "assets/icons/style.css";
@import "assets/icons/sitemule-ng-icons-v1.0/style.css";
.mat-menu-panel {
  max-width: 500px !important;
}
html {
  scroll-behavior: smooth !important;
  @media print {
    app-header,
    .search-container,
    app-footer,
    .floating-button {
      display: none !important;
    }
    app-header-and-footer > .content {
      margin: 0 !important;
    }
  }
}
.dialogIE {
  mat-dialog-container {
    border-radius: 0 !important;
    padding: 48px !important;
  }
}

body {
  margin: 0;
  font-family: "Open sans", sans-serif;
}
.mat-menu {
  font-family: "open sans";
}


/* Term and services popup styles */
.tos-dialog-backdrop {
  background-color: rgba($color: #000000, $alpha: .5);

}
.tos-dialog-panel {
  mat-dialog-container {
    border-radius: 5px !important;
  }
  .mat-dialog-content {
    margin: 0;
    padding: 20px 26px;

    page-article article {
      padding: 0;
    }
  }
  .mat-dialog-actions {
    padding: 17px 0;
  }
}
/* Term and services popup styles */


sm-teaser-layout-4 {
  --border-radius: 8px;
  --content-margin: 24px;
}
