@use 'variables';
@use "sass:map";
//STYLETODO use original design from kenneth. Make use of the basket componenet, and make it fit. https://marvelapp.com/prototype/fj91677/screen/76344397
@mixin user-basket-dialog-theme($theme) {
  user-basket-dialog {
    .basket-container {
      height: 100vh;
      display: grid;
      grid-template-rows: auto 1fr auto;
      color: #313131;
      flex-direction: column;
      position: relative;

      @media (min-width: map-get(map-get($theme, "breakpoints"), "phone")) {
        width: 500px;
      }

      > * > * {
        padding: 1rem;
      }
      .header {
        border-bottom: 1px solid #d9d9d9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        h2 {
          font-size: 2rem;
          color: #313131;
          font-weight: 400;
        }
        i {
          font-size: 2rem;
        }
      }
      .message {
        display: flex;
        justify-content: space-between;
        height: 48px;
        border-bottom: 1px solid #d9d9d9;
        align-items: center;
        .ui-button {
          background-color: rgba(53, 53, 53, 0.08);
        }
      }
      .content {
        overflow: auto;
        user-pricelist {
          padding: 0;
          .basket-list .basket-item{
            &:first-child{
            border-top: none !important;
            }
          }
        }
      }
      .teaser {
        background: rgba(53, 53, 53, 0.08);
        border-bottom: 1px solid white;
      }
      .spacing-line{
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid white;
      }
      .bottom {
        .offer {
          background: rgba(53, 53, 53, 0.08);
          border-bottom: 1px solid white;
          height: 60px;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: bold;
        }
        .price {
          font-size: 16px;
          background: rgba(53, 53, 53, 0.08);
          .line {
            display: flex;
            justify-content: space-between;
            p {
              margin-top: 0;
              margin-bottom: 8px;
            }
            .totalpricebasket {
              font-weight: bold;
            }
          }
          button {
            margin-top: 10px;
            width: 100%;
          }
        }
      }
      .padding {
        padding: 1rem;
      }

      // If it is first child, it means there is nothing rendered yet
      ngx-loading:first-child {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      // If it is not first child, it means there is basket rendered and we don't want to show mask over whole basket dialog on loading
      ngx-loading:not(:first-child) {
        position: absolute;
        bottom: 12px;
        left: 12px;
        right: 12px;
        height: 48px;

        .backdrop {
          background-color: transparent !important;
        }
      }
    }
  }
}

@mixin mobileStyle {
  .table-header-line {
    display: none;
  }
  .basket-list .basket-item {
    padding: 1rem 1rem 2rem 0;
    grid-template-columns: 149px 1fr;
    grid-row-gap: 0;
    grid-template-areas:
    "function function function"
      "image title title"
      "image unitprice ."
      "image amount price ";
    &:focus-within {
      background-color: #eeeff37d;
    }
    .thumbnail {
      mix-blend-mode: multiply;
      grid-area: image;
    }
    .info-text {
      grid-area: title;
      .title {
        font-size: 14px;
        margin-bottom: 12px;
      }
      p {
        font-size: 12px;
      }
    }

    .item-price {
      grid-area: unitprice;
      text-align: left;
      font-size: 12px;
      margin-bottom: 10px;
    }
    .amount {
      text-align: left;
      max-width: 72px;
      grid-area: amount;
    }
    .total-price {
      grid-area: price;
    }
  }
}

$desktopGridSize: 149px 1fr 72px 90px 90px;
.price-list-container {
  .basket-list {
    .table-header-line {
      .head-container {
        padding: 15px 8px 5px;
        border-radius: 5px 5px 0px 0px ;
        display: grid;
        background: #EEEDEE;
        grid-template-columns: $desktopGridSize;
        column-gap: 30px;
        text-align: right;
        .head {
          flex: 1;
        }
      }
    }
    .basket-item {
    grid-template-areas:
    "function function function function function";
      position: relative;
      border-top: 1px solid #d9d9d9;
      padding: 0.55rem 0;
      display: grid;
      grid-template-columns: $desktopGridSize;
      column-gap: 30px;
      font-size: 14px;
      align-items: center;

      &:last-child {
        border-bottom: 1px solid #d9d9d9;
      }
      .functions {
        align-items: center;
        grid-column: span 4;
        justify-content: flex-end;
        grid-area: function;
        display: flex;
        .inventory-status {
          position: static;
        
          margin-left: 10px;
          margin-right: 10px;
        }
        .inventory {
          flex: 1;
          font-size: 11px;
          color: #4A4A4A;
        }
        .delete {
          cursor: pointer;
          color: #4A4A4A;
          font-size: 25px;
        }
      }
      .info-text {
        .title {
          font-size: 18px;
          color: #313131;
          margin-top: 9px;
          margin-bottom: 8px;
        }
        > p {
          margin: 0;
        }
      }
      .amount {
        text-align: right;
      }
      .item-price,
      .total-price {
        text-align: right;
        font-size: 16px;
      }
      .total-price {
        font-weight: bold;
      }
    }
  }
  .price {
    display: flex;
    justify-content: flex-end;
    .content {
      margin-top: 30px;
      flex: 0 0 64%;
      .line {
        display: flex;
        padding: 5px 0;
        color: #353535;
        font-size: 14px;
        justify-content: space-between;
      }
      .final-price {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid #d9d9d9;
        .line {
          font-size: 16px;
          font-weight: bold;
        }
      }
      .spacing-line{

        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid #d9d9d9;
      }
      .accept-payment {
        display: grid;
        margin-top: 30px;
        margin-bottom: 30px;
        grid-gap: 5px;
        grid-template-columns: 1fr auto;
        .checkboxes {
          .checkbox {
            padding: 5px 0;
            font-size: 14px;
            display: flex;
            align-items: center;
          }
        }
        button {
          font-size: 16px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    @include mobileStyle;
  }
  &.mobile {
    @include mobileStyle;
  }
}

:root {
  --primary-color-50: #fef9f9;
  --primary-color-100: #fdeff0;
  --primary-color-200: #fbe5e6;
  --primary-color-300: #f8d5d6;
  --primary-color-400: #f5bcbd;
  --primary-color-500: #ef9295;
  --primary-color-600: #e36c6f;
  --primary-color-700: #e96a6e;
  --primary-color-800: #e6565a;
  --primary-color-900: #e2383d;
  --primary-color-hover-50: #fadcdd;
  --primary-color-hover-100: #f9d3d5;
  --primary-color-hover-200: #f7cacc;
  --primary-color-hover-300: #f5bec0;
  --primary-color-hover-400: #f2a7a9;
  --primary-color-hover-500: #e57d80;
  --primary-color-hover-600: #e36c6f;
  --primary-color-hover-700: #e05e60;
  --primary-color-hover-800: #db4c50;
  --primary-color-hover-900: #d73338;

  --accend-color-50: #e8edf1;
  --accend-color-100: #d2dde3;
  --accend-color-200: #bccbd5;
  --accend-color-300: #a5bac7;
  --accend-color-400: #8ea9b9;
  --accend-color-500: #8ea9b9;
  --accend-color-600: #7798ac;
  --accend-color-700: #60869d;
  --accend-color-800: #336481;
  --accend-color-900: #1d5374;
  --accend-hover-color-50: #c9d5de;
  --accend-hover-color-100: #b7c8d2;
  --accend-hover-color-200: #a3b9c6;
  --accend-hover-color-300: #91abbc;
  --accend-hover-color-400: #7d9caf;
  --accend-hover-color-500: #7697aa;
  --accend-hover-color-600: #6489a0;
  --accend-hover-color-700: #517a93;
  --accend-hover-color-800: #2c5c78;
  --accend-hover-color-900: #1a4e6e;

  --black-color-50: #f7f7f7;
  --black-color-100: #ebebeb;
  --black-color-200: #dedede;
  --black-color-300: #c9c9c9;
  --black-color-400: #a8a8a8;
  --black-color-500: #737373;
  --black-color-600: #595959;
  --black-color-700: #4c4c4c;
  --black-color-800: #262626;
  --black-color-900: #191919;
  --black-hover-color-50: #d3d3d3;
  --black-hover-color-100: #c8c8c8;
  --black-hover-color-200: #bdbdbd;
  --black-hover-color-300: #acacac;
  --black-hover-color-400: #8f8f8f;
  --black-hover-color-500: #5b5b5b;
  --black-hover-color-600: #474747;
  --black-hover-color-700: #3d3d3d;
  --black-hover-color-800: #1e1e1e;
  --black-hover-color-900: #141414;

  --slate-color-50: #f8f7f7;
  --slate-color-100: #edecec;
  --slate-color-200: #e2e0e0;
  --slate-color-300: #cfcccc;
  --slate-color-400: #b2adad;
  --slate-color-500: #837c7c;
  --slate-color-600: #6c6363;
  --slate-color-700: #564c4c;
  --slate-color-800: #3f3434;
  --slate-color-900: #1e0f0f;
  --slate-hover-color-50: #d7d5d5;
  --slate-hover-color-100: #cdcaca;
  --slate-hover-color-200: #c4c0c0;
  --slate-hover-color-300: #b5b0b0;
  --slate-hover-color-400: #9b9595;
  --slate-hover-color-500: #6e6666;
  --slate-hover-color-600: #5c5151;
  --slate-hover-color-700: #4a3f3f;
  --slate-hover-color-800: #362c2c;
  --slate-hover-color-900: #000000;
}

@mixin theme-basket($theme) {
  @include user-basket-dialog-theme($theme);
}

@mixin webshop-catalog-item-theme($theme) {
  //STYLE fix inventory icon. Make a bit smaller and not squareish$, use 0.8rem
  webshop-catalog-item {
    @include sharedStyle($theme);
    &.list {
      @media (max-width: map-get(map-get($theme, "breakpoints"), "phone")) {
        @include galleryMode($theme);
      }
      @media (min-width: map-get(map-get($theme, "breakpoints"), "phone")) {
        @include listMode($theme);
      }
    }
    &.grid {
      @include galleryMode($theme);
    }
    &.gallery {
      @include galleryMode($theme);
    }
  }
}
@mixin sharedStyle($theme) {
  .catalog-item {
    position: relative;

    .text-container {
      .body {
        > p {
          margin: 0;
        }
      }
    }
  }
  background: white;
  a {
    color: #313131;

    text-decoration: none;
  }
  .catalog-item.placeholder {
    .image-container {
      > .icon,
      .image {
        display: none !important;
      }
    }
  }
  .price-container {
    background: map-get(map-get($theme, "element"), "dark");
    padding: 2.5em 1em 1.2em 1em;
    text-align: center;
    flex: 0 0 100px;
    p {
      margin-top: 0px;
      margin-bottom: 5px;
    }
    .price,
    .suffix,
    .prefix {
      .text {
        font-size: 2em;
        font-weight: bold;
        color: #313131;
      }
    }
    .suffix,
    .prefix {
      color: map-get($theme, "warning");
    }
  }
  .actions {
    border-top: 1px solid white;
    display: flex;
    gap: 1px;
    font-size: 1.6em;
    > div {
      flex: 1;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: map-get(map-get($theme, "element"), "dark");
    }
    .cart {
      &.disabled {
        cursor: not-allowed;
        //pointer-events: none;
        opacity: 0.6;
      }
      &.inBasket {
        background-color: map-get($map: $theme, $key: "success");
        color: white;
      }
    }
  }
}
@mixin galleryMode($theme) {
  margin: 5px;
  .catalog-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid map-get(map-get($theme, "element"), "dark");
    cursor: pointer;
    .image-container {
      padding: 5px;
      display: flex;
      width: 100%;
      height: 185px;

      .icon {
        display: flex;
        font-size: 10px;
        flex: 1;
        font-size: 70px;
        justify-content: center;
        align-items: center;
      }

      .image {
        text-align: center;
        height: 100%;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .text-container {
      width: 100%;
      height: 60px;
      padding: 10px;
      text-align: center;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;

      &.expanded {
        height: 150px;
        text-align: left;

        .product-number {
          margin-bottom: 0.5em;
        }

        .body {
          //flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          color: #313131;
        }

        .center {
          justify-content: unset;
        }
      }

      .center {
        width: 100%;
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .name {
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #313131;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}

@mixin listMode($theme) {
  .catalog-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid map-get(map-get($theme, "element"), "dark");
    height: 80px;

    &:hover {
      cursor: pointer;
    }

    .image-container {
      padding: 0.5em;
      display: flex;
      width: 100%;
      height: 100%;
      flex: 3;

      .icon {
        display: flex;
        font-size: 10px;
        justify-content: center;
        align-items: center;
      }

      .image {
        height: 100%;
        width: 100%;
        text-align: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .text-container {
      padding: 1em 2.2em;
      padding-left: 0.5em;
      width: 100%;
      height: 100%;
      flex: 1;
      flex-direction: column;
      display: flex;
      .name {
        color: #313131;
        font-size: 1.4em;
        line-height: 24px;
        margin-bottom: 2px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .center {
        width: 100%;
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .product-number {
          margin-bottom: 5px;
        }
        .body {
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
    .price-container {
      text-align: left;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 1.2em;
      .price {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        h3 {
          margin-top: 23px;
          margin-bottom: 14px;
        }
      }
      .price,
      .leading-text {
      }
    }
    .actions {
      border-top: none;
      border-left: 1px solid white;
      height: 100%;
      flex-direction: row;
      > div {
        width: 50px;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
    }
  }
  @media (min-width: map-get(map-get($theme, "breakpoints"), "phone")) {
    margin: 5px;
    .catalog-item {
      height: 115px;
      border: 1px solid map-get(map-get($theme, "element"), "dark");

      .image-container {
        flex: 0 0 145px;
      }

      .price-container {
        flex: 0 0 200px;
      }
    }
  }
}

@mixin webshop-catalog-theme($theme) {
  webshop-catalog {
    position: relative;
    display: block;
    width: 100%;
    .catalog-container {
      width: 100%;
      height: 100%;
      
      display: grid;
      @media (min-width: map-get(map-get($theme, "breakpoints"), "phone")) {
          display: grid;
          &.grid{
            grid-template-columns: repeat(auto-fit, 25%);
          }
          &.gallery{
            grid-auto-columns: 20%;
          }
          &.list{
            grid-template-columns: 1fr;
          }
        }
    }
    
  }
}

@mixin webshop-catalog-mini-theme($theme) {
  webshop-catalog-mini {
    > button {
      display: block;
      margin: auto;
    }
    webshop-catalog .catalog-container {
      &.grid {
        @media (min-width: map-get(map-get($theme, "breakpoints"), "phone")) {
          grid-template-columns: repeat(auto-fit, 20%);
        }
        .catalog-item {
          background-color: white;
        }
      }
      &.list {
        @media (min-width: map-get(map-get($theme, "breakpoints"), "phone")) {
          grid-template-columns: repeat(auto-fit, 384px);
          .catalog-item {
            display: grid;
            grid-template-columns: 120px 1fr 50px;
            grid-template-areas:
              "image text button"
              "image price button";
            .image-container {
              grid-area: image;
              padding: 0;
            }
            .text-container {
              grid-area: text;
              .product-number {
                display: none;
              }
              .name {
                font-size: 1.2rem;
                font-weight: 700;
                word-break: break-all;
                -webkit-line-clamp: 1;
              }
              .body {
                display: none;
              }
            }
            .price-container {
              grid-area: price;

              padding: 0.1em 0.5em 1em;
              background: transparent;
              .price {
                display: flex;
                &.has-customer-price {
                  justify-content: space-between;
                  align-items: flex-end;
                }
                .label {
                  margin-right: 1rem;
                  span {
                    display: none;
                  }
                }
                h3 {
                  margin: 0;
                  font-size: 3rem;
                  font-weight: 100 !important;
                }
              }
            }
            .actions {
              grid-area: button;
              .favorit {
                display: none;
              }
              .cart {
                padding: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}


@mixin webshop-filter-theme($theme) {
  webshop-catalog-filter {
    .filter-container {
      .filter-group {
        margin-bottom: 20px;

        .filter-header {
          display: flex;
          align-items: center;
          color: map-get(map-get($theme, "text"), "dark");
          font-weight: bold;
          font-size: 1.1em;
          padding: 0.2em;
          cursor: pointer;
        }

        .filter-children {
          .filter-child {
            color: map-get(map-get($theme, "text"), "dark");
            display: flex;
            align-items: center;
            height: 30px;
            cursor: pointer;

            &.disabled {
              color: lighten($color: #000000, $amount: 75%);
              pointer-events: none;
              cursor: not-allowed;
            }

            .checkbox {
              border: 1px solid map-get(map-get($theme, 'element'), 'base');
              flex: 0 0 20px;
              height: 20px;
              margin-right: 15px;
              display: flex;
              justify-content: center;
              color: map-get($theme, 'primary');
              align-items: center;
              font-size: 16px;
              i {
              font-size: 16px;
              }
            }
          }
        }
      }
    }

    @media (min-width: map-get(map-get($theme, "breakpoints"), "phone")) {
    }
  }
}

@mixin webshop-menu-theme($theme) {
  webshop-catalog-menu{
    display: flex;
    .option{
      font-size: 16px;
      color: map-get($theme, 'primary');
      padding: 5px;
      margin-right: 10px;
      position: relative;

      &:not(:last-of-type){
      display: none;
      };
    }
      ui-menu{
        z-index: 1;
        top: 100%;
        left: 0;
        position: absolute;
    }
    i.active{
        transition: transform 0.2s;
        transform: rotate(0deg);
        display: inline-flex;
        &.active{
          transform: rotate(90deg);
        }
    }
  @media (min-width: map-get(map-get($theme, "breakpoints"), "phone")) {
    .option{
      &:not(:last-of-type){
        display: flex;

        };
      &:hover{
        cursor: pointer;
        background: map-get(map-get($theme, 'element'), 'light');
      }
    }
    ui-menu{
      left: unset;
      right: 0;

    }
  }
  }
}

@mixin webshop-product-theme($theme) {
  webshop-product {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 3fr 2fr;
    color: #353535;
    .tab-icon {
      grid-column: span 2;
      position: relative;
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-end;
      .tab-header-container {
        .tab-header {
          display: inline;
          cursor: pointer;
          padding: 1.4em;
          padding-left: 0;
          padding-right: 2.2em;
          font-size: 1.3em;
          color: #106dbe;
          &.active {
            color: map-get(map-get($theme, "text"), "dark");
          }
        }
      }
      .product-buttons {
        display: flex;
        font-size: 12px;
        .button {
          background-color: transparent;
          border: none;
          font: inherit;
          cursor: pointer;
          height: 100%;
          width: 50px;
          margin-left: 4px;
          justify-content: center;
          align-items: flex-end;
          display: flex;
          flex-direction: column;

          i {
            font-size: 25px;
          }
        }
      }
    }
    .image-slider-container {
      grid-column: span 2;

      ngx-sitemule-document-list {
        margin-top: 40px;
      }
    }

    .info-container {
      color: #353535;
      position: relative;
      grid-column: span 2;
      margin-bottom: 20px;
      font-size: 16px;
      flex-direction: column;
      .title {
          color: #353535;
          font-size: 29px;
          margin-bottom: 20px;
          line-height: 36px;
          font-weight: 400;
      }

      .description {
        line-height: 1.5;
        margin-bottom: 20px;
        max-width: 700px;
      }
      .info-grid {
        width: 100%;
        margin-top: 38px;
        margin-bottom: 30px;

        .info-grid-item {
          display: flex;
          justify-content: space-between;
          padding: 7px 0;
          padding-right: 50px;
          border-bottom: 1px solid map-get(map-get($theme, "element"), "dark");
          &:first-child {
            border-top: 1px solid map-get(map-get($theme, "element"), "dark");
          }
        }
      }

      .leading-text {
        font-size: 12px;
      }
      .price-container {
        margin-top: 65px;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        .price {
          display: flex;
          flex-direction: column;
          margin-bottom: 3px;
          h3 {
            font-weight: bold;
            color: #353535;
            font-size: 36px;
          }
        }
      }
      .inventory {
        display: none;
        align-items: center;
        font-size: 12px;
        margin-top: 5px;

        .inventory-status {
          margin-right: 0.5rem;
          position: inherit;
        }
      }

      .buy {
        margin-top: 1em;
        font-size: 16px;
        display: block;
      }
    }

    .tab-container {
      display: flex;
      flex-direction: column;
      grid-column: span 2;
    }

    .document-catalog {
      .catalog-container .scroll-container.grid {
        grid-template-columns: repeat(auto-fit, 50%);
      }
    }

    .related-products {
      margin-top: 2rem;
    }
    .related-products,
    .related-categories {
      padding: 2rem;
      h2 {
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
        color: #353535;
      }
      width: 100%;
      background: map-get(map-get($theme, "element"), "light");
      border-bottom: 1px solid white;
      grid-column: span 2;
      webshop-catalog {
        .catalog-container .scroll-container {
          overflow: hidden;

          &.grid {
            grid-template-columns: repeat(auto-fit, 20%);
          }
        }
      }
    }

    @media (min-width: map-get(map-get($theme, "breakpoints"), "phone")) {
      .image-slider-container {
        grid-column: span 1;
      }
      .info-container {
        grid-column: span 1;
        .title {
          color: #353535;
          font-size: 29px;
          margin-bottom: 20px;
          line-height: 46px;
          font-weight: 400;
          color: #353535;
          font-size: 48px;
          margin-bottom: 20 px;
          line-height: 56px;
          font-weight: 400;
        }
        .info-grid {
          width: 55%;
        }
      }
      .tab-container {
        .tab-header-container {
          .tab-header {
            flex: unset;
            width: 20%;
          }
        }
      }
      .document-catalog {
        .catalog-container .scroll-container.grid {
          grid-template-columns: repeat(auto-fit, 33%);
        }
      }
    }
  }
}

//product-template {
//  __<<ngThemingMigrationEscapedComment0>>__
//  display: none;
//}
//
//@media print {
//  __<<ngThemingMigrationEscapedComment1>>__
//  __<<ngThemingMigrationEscapedComment2>>__
//
//  product-template {
//    display: block;
//  }
//
//  webshop-product{
//  //.image-slider-container, .info-container, .related-products, .tab-container, .related-categories{
//    display: none !important;
//  }
//
//}

@mixin webshop-entry-theme($theme) {
  webshop-entry {
    .topbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      ui-breadcrumb {
        flex: 3;
      }

      .search-container {
        flex: 0 0 25%;
      }

    }

    .wshteaserstack {
      margin-top: 80px;
      margin-bottom: 25px;
    }

    @media (max-width: map-get(map-get($theme, "breakpoints"), "phone")) {
      .topbar {
        display: block;

        ui-breadcrumb {
          flex: 1;
          margin-bottom: 10px;
        }

        .search-container {
          flex: 1;
          width: 100%;
        }

        margin-bottom: 1rem;
      }
    }
  }
}

@media print {
  page-teaser-stack {
    display: none;
  }
}

@mixin webshop-catalog-entry-theme($theme) {
  webshop-catalog-entry {
    display: flex;
    flex-wrap: wrap;
    .header-filter-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      color: map-get(map-get($theme, "text"), "dark");

        margin-bottom: 1rem;

      .catalog-title {
        color: map-get(map-get($theme, "text"), "dark");
      }
      .amount {
        color: map-get(map-get($theme, "text"), "dark");
        i {
          font-size: 0.7em;
          padding-left: 2px;
        }
      }
      .menu-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
    }
    .content-container {
      flex: 4;
    }
    .left {
      width: 20%;
      padding-right: 10px;
    }

    ngx-sitemule-spare-parts {
      margin: 50px 5px 0 5px;
      display: block;
    }
    @media (max-width: map-get(map-get($theme, "breakpoints"), "phone")) {
      .menu-container {
        flex-direction: column;
        align-items: baseline;
      }
      .left {
        display: none;
      }
    }
  }
}


@mixin theme-webshop($theme) {
  @include webshop-catalog-theme($theme);
  @include webshop-catalog-mini-theme($theme);

  @include webshop-catalog-item-theme($theme);
  @include webshop-product-theme($theme);
  @include webshop-filter-theme($theme);
  @include webshop-menu-theme($theme);
  @include webshop-entry-theme($theme);
  @include webshop-catalog-entry-theme($theme);
  .inventory {
    display: flex !important;
  }
  .inventory-status {
    position: absolute;
    height: 0.6rem;
    width: 0.6rem;
    top: 0.5rem;
    left: 0.5rem;
    border-radius: 100%;
    background: white;
    &.IN_STOCK {
      background: map-get($map: $theme, $key: success);
    }
    &.ON_THE_WAY {
      background: map-get($map: $theme, $key: warning);
    }
    &.SOLD_OUT {
      background: map-get($map: $theme, $key: danger);
    }
  }
}

@mixin ui-slider-theme($theme) {
  ui-slider{
    color: map-get($theme, 'primary');
    font-size: 32px;
  }
}

ui-slider {
  width: 100%;
  height: 100%;
  display: flex;
}
.ui-slider-wrapper {
  position: relative;
  overflow: hidden;
  flex: 1 1 auto;
  .slides-container {
    height: 100%;
    display: flex;
  }
}
.animating {
  transition: 0.5s;
}

@mixin ui-tab-theme($theme) {
  ui-tab {
    > * {
      display: none;
      width: 100%;
      &.active {
        display: unset;
      }
    }
  }
}

@mixin ui-tooltip-theme($theme) {

.tooltip {
  position: absolute;
  max-width: 90%;
  font-size: 14px;
  text-align: center;
  color: #f8f8f2;
  padding: 5px;
  background: #1e1e1f;
  z-index: 1000;
}
}

@mixin ui-menu-theme($theme) {
  //.menu-item ~ ui-menu .ui-menu-container .menu-item {
  //  margin-left: 35px;
  //  padding-left: 10px;
  //}
  .ui-menu-container {
    ui-menu {
      .ui-menu-container {
        margin-left: 20px;
        padding-left: 10px;
      }
    }
  }
  ui-menu {
    .ui-menu-container {
      /* Sticky menu, stays as scrolling */
      position: -webkit-sticky; /* Safari */
      position: sticky;
      top: 50px;
      .ui-menu-container {
        position: relative;
        top: 0;
      }
      &:focus {
        outline: none;
      }

      .menu-item {
        background: white;
        cursor: pointer;
        color: map-get(map-get($theme, "text"), "dark");
        padding: 5px 10px;
        font-size: 14px;
        display: flex;

        a {
          color: map-get(map-get($theme, "text"), "dark");
          text-decoration: none;
          display: block;
        }

        .icon {
          font-size: 18px;
          margin-right: 10px;
        }

        &:hover {
          background-color: map-get(
            map-get($theme, "element"),
            "base"
          ) !important;
        }

        &.activeItem {
          font-weight: bold;
        }

        .label-container {
          flex: 1;
        }
      }

      ui-menu .ui-menu-container {
        .menu-item {
          background: white;
        }

        .menu-item:first-child {
          border-top: none;
        }
      }
    }
  }
}

@mixin ui-menu-dropdown-theme($theme) {
    ui-menu.dropdown{
        background: white;
        padding: 10px;
        min-width: 200px;
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    }
}

@mixin ui-image-slider-theme($theme) {
  .ui-slider-container{
    flex: 1;

    &:hover{
	    .pz-zoom-button{
		    opacity: 0.5 !important;
	    }
    }
  .pz-zoom-button {
    opacity: 0.0 !important;
    left: 90% !important;
    &:hover{
      opacity: 0.5 !important;
    }
  }
  }
  ui-image-slider{
    img {
      &.active {
      }
    }
  }
ui-image-slider {
  display: flex;
  flex-direction: column;
  height: 100%;
  .slide {
    width: 100%;
    position: relative;
    .image-container {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      max-width: 100%;
      max-height: 100%;
    }
    .youtube{
      height: 100%;
      width: 100%;
    }
  }

  .thumbnails {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex: 0 0 60px;
    justify-content: center;
    height: 100px;
    .thumbnail {
      margin: 0 5px;
      height: 75px;
      width: 95px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      &.active {
        border-bottom: 1px solid map-get(map-get($theme, 'element'), 'dark');;
      }
    }
  }
}
}

@mixin ui-business-card-theme($theme) {
  ui-business-card {
    .contact {
      overflow: auto;
      font-size: 13px;
      &::after {
        content: "";
        clear: both;
        display: table;
      }

      .contact__name {
      }
      .contact__title {
        margin-bottom: 10px;
      }
      @media print {
        page-break-inside: avoid;
      }
    }
    .contact__image{
      height: 250px;
      display: flex;
      background: map-get(map-get($theme, "element"), "base");
      img {
        width: 100%;
      }
    }

  }
}

@mixin ui-business-card-grid-theme($theme) {
  @include ui-business-card-theme($theme);
  ui-business-card-grid {
      .contact-grid {
        display: flex;
        flex-wrap: wrap;
        h2{
          width: 100%;
          margin-bottom: 10px  ;
          &:not(:first-child){
            margin-top: 30px;
          }
        }
        .contact-container{

          width: 250px;
          margin-right: 20px;
          margin-bottom: 30px;

        }
        &:not(:last-child){
          margin-bottom: 80px;
        }
      }

    @media (max-width: 767px) {
      .contact-grid{
        justify-content: center;
        h2{
          text-align: center;
        }
      }
    }
  }
}

@mixin ui-animated-burger-menu-theme($theme){
  ui-animatd-burger-menu-icon{
    height: 100%;
    width: 100%;
    button.menu-toggler{
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .navigation-hamburger{
        .hamburger-line{
          height: 2px;
          background: black;
          width: 80%;
        }
      }
    }
  }
}

@mixin ui-theme-accordion($theme) {
  button {
    font-family: inherit;
  }
  ui-accordion {
    .accordion {
      width: 100%;
      display: block;
      background-color: #ffffff;
      color: map-get(map-get($theme, 'text'), 'dark');
      //padding: 18px;
      height: 60px;
      padding: 0 10px 0 10px;
      border: 1px solid map-get(map-get($theme, 'element'), 'dark');
      text-align: left;
      outline: none;
      font-size: 22px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .right{
        display: flex;
      }
      &:last-child {
        background-color: purple;
        margin-bottom: 0;
      }


      &:disabled {
        background-color: map-get(map-get($theme, 'element'), 'light');
      }

      @media (max-width: map-get(map-get($theme, "breakpoints"), "phone")) {
        width: 100%;
      }

    }

    .active, .accordion:hover:not(:disabled) {
      color: map-get($theme, 'primary');

      cursor: pointer;

    }

    .panel {
      display: none;
      margin: 10px 0;
      background-color: white;
      overflow: hidden;
    }
    @media print{
      .panel{
        display: block !important;
      }
      button > span{
        display: none;
      }
    }
  }
}



//STYLETODO Create input with button component style
@mixin ui-input-theme($theme) {
  input {
    font-family: inherit;
  }
  .ui-input {
    @include input($theme);
  }
  .ui-input-checkbox {
    &:invalid,
    &.ng-invalid {
      &.ng-touched {
        box-shadow: 0px 0px 0px 1px red;
      }
    }
  }
  .ui-input-icon-group {
    @include icon($theme);
  }
  @include group($theme);
  .ui-input-combined {
    @include combined($theme);
  }
}

@mixin combined($theme) {
  display: flex;
  > .ui-button,
  .ui-input,
  * {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    //ONLY INPUT
    &.ui-input {
    }
  }
}

@mixin icon($theme) {
  position: relative;
  display: grid;
  align-items: center;
  width: 100%;
  input,
  select,
  textarea {
    @include input($theme);

    & ~ i {
      right: 1em;
      background: inherit;
    }
  }
  i {
    padding-left: 0.5em;
    font-weight: 400;
    justify-content: center;
    font-size: 1.5em;
    display: flex;
    align-items: center;
    position: absolute;

    & ~ input {
      padding-left: 2.5em;
    }
  }
}

@mixin label($theme) {
}

@mixin input($theme) {
  font-family: inherit;
  border-radius: 5px;
  font-size: inherit;
  width: 100%;
  color: #353535;
  border: 1px solid map-get(map-get($theme, "element"), "dark");
  flex: 1;
  padding: 1em;
  background-color: white;
  &:focus {
    border: 1px solid map-get($theme, "primary");
  }
  &:invalid,
  &.ng-invalid {
    &.ng-touched {
      box-shadow: 0px 0px 0px 1px red;
      border-radius: 5px;
    }
  }
  &::placeholder {
    color: map-get(map-get($theme, "text"), "dark");
  }
}

@mixin group($theme) {
  .ui-input-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    gap: 4px;
    grid-template-areas:
      "top top"
      "middle middle"
      "bottom bottom";
    margin-bottom: 25px;
    &:last-of-type {
      margin-bottom: 0;
    }
    label,
    .label {
      grid-area: top;
      color: #353535;
    }

    @include icon($theme);

    .ui-input-checkbox {
      margin-bottom: 10px;
      position: relative;

      input {
        position: absolute;
        opacity: 0;
      }

      label {
        display: flex;
        align-items: center;
        gap: 6px;
      }

      label::before {
        content: '';
        height: 24px;
        width: 24px;
        border-radius: 50%;
        border: 1px solid #A8A8A8;
        display: block;
      }
      input:focus + label::before {
        outline: -webkit-focus-ring-color auto 1px;
        outline-color: -webkit-focus-ring-color;
        outline-style: auto;
        outline-width: 1px;
      }
      input:checked + label::before {
        border-color: var(--primary-color);
      }
      input:checked + label::after {
        content: '';
        height: 16px;
        width: 16px;
        border-radius: 50%;
        display: block;
        background-color: var(--primary-color);
        position: absolute;
        left: 5px;
      }
      label {
        color: #737373;
      }
    }



    select,
    input,
    textarea {
      @include input($theme);
      grid-area: middle;
    }

    .hint {
      grid-area: bottom;
    }

    .error {
      grid-area: bottom;
      color: red;
    }
  }
}

@mixin ui-button-theme($theme) {
  .ui-button {
    padding: 0.8em 1.5em;
    border-radius: 5px;
    border: none;
    font-size: 1em;
    font-family: inherit;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: bold;
    &.success {
      background-color: map-get($theme, "success");
      color: white;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &.flat {
      background-color: transparent;
    }
  }
}


@mixin theme-ui($theme) {
  @include ui-slider-theme($theme);
  @include ui-image-slider-theme($theme);
  @include ui-tab-theme($theme);
  @include ui-business-card-grid-theme($theme);
  @include ui-menu-theme($theme);
  @include ui-menu-dropdown-theme($theme);
  @include ui-input-theme($theme);
  @include ui-button-theme($theme);
  @include ui-tooltip-theme($theme);

  @include ui-animated-burger-menu-theme($theme);
  @include ui-theme-accordion($theme);

  $content-width: 1200px;

  .project-content-margin {
    max-width: $content-width;
    margin: auto;
    @media (max-width: calc(#{$content-width} + 5px)) {
      margin: 0 20px 0 20px;
    }
    @media  (max-width: map-get(map-get($theme, "breakpoints"), "phone")){
        margin: 0 10px;
    }
    &.full-width{
      margin: 0;
      max-width: unset;
      @media  (max-width: map-get(map-get($theme, "breakpoints"), "phone")){
        margin:0;
      }
      @media  (min-width: 1200px){
        margin:auto;
      }

    }
  }
  @page { size: auto;  margin: 5mm;

  }
  @media print{
    html{
    }
  }
  * {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
    color-adjust: exact !important;
    box-sizing: border-box;

  }
}

.scroll-container{

}

.ui-dialog{
  .mat-dialog-container{
    padding: 0 !important;
  }
}
.small-dialog{
  min-width: 402px;
  .mat-dialog-container{
    padding: 74px 31px 35px !important;
  }
}
.mat-dialog-container{
  border-radius: 0 !important;
}
.mat-menu-panel {
  border-radius: 0 !important;
  .mat-menu-content{
    padding: 0 !important;
  }
}
//table.
.ui-table.pretty{
    > *:first-child {
      height: 50px;
      background: white;
      > * {
        cursor: pointer;
        &.active{
          cursor: pointer;
        color: #4A4A4A;
          background: #EBEBEB;

        }
        vertical-align: middle;
      }
    }
}
  .ui-table.sticky  {
    > *:first-child {
      height: 50px;
      background: white;
      position: sticky;
      top: 49px;
      > * {
        cursor: pointer;
        &.active{
          cursor: pointer;
        color: #4A4A4A;
          background: #EBEBEB;

        }
        vertical-align: middle;
      }
    }
  }

.ui-table {
  margin-top: 30px;
  font-size: 14px;
  --header-bg-color: #eeedee;
  --border-color: #e3e2e2;
  --row-bg-color: white;
  background: white;
  width: 100%;
  color: black;
  position: relative;
  border-radius: 3px;
  border-collapse: collapse;
  display: table !important;
  //Rows
  > * {
    display: table-row;
    border: 1px solid var(--border-color);
    //set header styling
    transition: all 0.2 ease-in-out;
    &:first-child {
      height: 80px;
      z-index: 2;
      border-radius: 5px 5px 0 0;
      background: var(--header-bg-color);
      > * {
        vertical-align: bottom;
        font-weight: bold;
        color: #868485;
      }
      &.isSticky {
        box-shadow: 0 12px 12px 0 rgb(0 0 0 / 22%);
        border: 1px solid transparent;
        border-radius: 5px 5px 0 0;
        &::after{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 1px;
          background: var(--border-color);
          z-index: -1;
        }
      }
    }
    &:not(:first-child) {
      background-color: var(--row-bg-color);
      &:hover {
        background-color: #f8f8f8;
      }
    }
    > * {
      transition: all 0.2s ease-in-out;
      //Cells
      padding: 1em;
      display: table-cell;
    }
    //Set border on all rows
    &:nth-child(2) {
      border-top: 1px solid var(--border-color);
    }
    &:not(:last-child):not(:first-child) {
      border-top: none;
    }
  }
}
.pagination-container {
  text-align: center;
  height: 56px;
  width: 100%;
  color: #868485;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 20px;
  .numbers {
    height: 100%;

    border-radius: 5px;
    height: 56px;
    align-items: center;
    display: flex;
    gap: 5px;
    justify-content: center;

    background: white;
    .icon {
      display: flex;
      align-items: center;

      height: 100%;
      padding: 0 10px;
      &:first-child {
        margin-right: 5px;
        border-right: 1px solid #e3e2e2;
      }
      &:last-child {
        margin-left: 5px;
        border-left: 1px solid #e3e2e2;
      }
    }
    span {
      border-radius: 5px;
      height: 36px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s all ease-in-out;
      &:hover {
        background-color: #ebebeb;
      }

      &.current {
        background-color: #4a4a4a;
        color: white;
      }
    }
  }
  select {
    border-radius: 5px;
    padding: 15px;
    border: none;
    height: 100%;
  }
}

@mixin page-article-theme($theme) {
  page-article {
    article {
      overflow: hidden;
      padding: 40px 0;
      font-size: 16px;
      line-height: 26px;
      border-radius: var(--article-image-border-radius);

      .text-container {
        .header {
          font-size: 36px;
          line-height: 40px;
          margin: 0 0 16px;
          font-weight: var(--bold-font-weight);
        }
        .subheader {
          font-size: 16px;
          line-height: 22px;
          font-weight: var(--bold-font-weight);
          margin: 0;
        }
        .html_content {
            margin: 0 0 36px;
            font-size: 16px;
            line-height: 22px;
            p {
              margin: 0;
            }
        }
      }

      .image {
        display: flex;
        align-items: center;
        position: relative;

        &.h-left,
        &.lc {
          justify-content: flex-start;
        }

        &.h-center {
          justify-content: center;
        }

        &.h-right {
          justify-content: flex-end;
        }

        &.v-top {
          align-items: flex-start;
        }

        &.v-center {
          align-items: center;
        }

        &.v-bottom {
          align-items: flex-end;
        }

        img {
          max-width: 80%;
          max-height: 400px;
          border-radius: var(--article-image-border-radius);
        }
      }

      .text-container {
        grid-row: 1 / span 3;
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 1200px;

        .actions {
          .ui-button {
            background: map-get($theme, 'primary');
            color: white;
            border-radius: var(--article-button-radius, 36px);
          }
        }

        @media (max-width: 920px) {
          padding-top: 30px;
        }
      }

      &.full-width {
        padding-left: 20px !important;
        padding-right: 20px !important;
      }

      &.padding {
        padding-left: 20px;
        padding-right: 20px;
      }

      &.inverse {
        //FIXME: Hack until backend supports this
        color: white;

        h1,
        h2, h3 {
          color: white;
        }

        .text-container {
          .actions {
            .ui-button {
              background: white;
              color: map-get($theme, 'primary');
              border-radius: var(--article-button-radius, 36px);
            }
          }

        }
      }

      &.bordered {
        border: 1px solid map-get(map-get($theme, "element"), "dark");
      }
    }

    @media (min-width: 720px) {
      .layout-cc {
        grid-template-columns: 100%;
        margin: auto;
        max-width: 1000px;
      }

      .layout-rl,
      .layout-wide-left,
      .layout-rr,
      .layout-wide-right {
        display: grid;
        gap: 10px;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          display: block;
          .image {
            margin: 0px !important;
          }
        }

        .actions {
          sitemule-cms-button {
            display: inline-block;
            margin-right: 12px;
            margin-bottom: 12px;
          }
        }

        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: max-content auto;

        .image {
          grid-row: 1 / span 3;

          .youtube {
            height: calc(100% + 80px);
            width: calc(100% + 20px);
            margin-right: -20px;
          }
        }
      }
      .layout-rl,
      .layout-wide-left {
        header,
        .html_content {
          grid-column: 1;
        }

        .image {
          grid-column: 2;

          .youtube {
            height: calc(100% + 80px);
            width: calc(100%);
            //margin-left: -20px;
          }
          &.snapToEdge {
            margin: -40px -40px -40px 0px;
          }
        }
      }
      .layout-rr,
      .layout-wide-right {
        header,
        .html_content {
          grid-column: 2;
        }

        .image {
          grid-column: 1;


          &.snapToEdge {
            margin: -40px 0px -40px -40px;
          }
        }
      }

      .layout-wide-left {
        grid-template-columns: auto 250px;
      }

      .layout-wide-right {
        grid-template-columns: 250px auto;
      }

      .layout-wide-right {
        grid-template-columns: 250px auto;
      }
      .layout-teaser {
        text-align: center;
        padding-left: 20%;
        padding-right: 20%;

        .image.snapToEdge {
          margin-top: -40px;
        }

        .actions {
          sitemule-cms-button {
            margin-left: 8px;
            margin-right: 8px;
          }
        }
      }

      .layout-column {
        // TODO: we need to split text on <hr>
        margin: auto;
      }
    }
  }
}

@mixin page-media-slider-theme($theme) {
  page-media-slider {
    .media-slider-wrapper {
      position: relative;
      padding: 0 !important;
      overflow: hidden;

      &.full-width {
        max-width: unset;
        margin: 0;

      }

      &:hover {
        .navigation-button {
          opacity: 0.5;
        }
      }

      .slides-container {
        display: flex;
        height: 100%;
        max-width: none;

        .slide {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          background: #fff;
          background-position: center;
          text-decoration: none;

          .slide-background {
            clip-path: polygon(
                0% 0%,
                100% 0%,
                100% calc(100% - 14px),
                88px calc(100% - 14px),
                74px 100%,
                60px calc(100% - 14px),
                0% calc(100% - 14px)
            );
            flex: 1;
            padding: 20px;
            background-size: cover;
            display: flex;

            &.ha-left {
              justify-content: flex-start;
            }

            &.ha-center {
              justify-content: center;
            }

            &.ha-right {
              justify-content: flex-end;
              text-align: right;
            }

            &.va-top {
              align-items: flex-start;
            }

            &.va-center {
              align-items: center;
            }

            &.va-bottom {
              align-items: flex-end;
            }

            h2 {
              margin: 0;
            }
          }

          .caption-container {
            height: 100%;
            display: flex;
            width: 100%;
            align-items: flex-end;
            @media (min-width: 1920px) {
              width: 100%;
            }

            .caption {
              padding: 40px;
              width: 50%;

              p {
                font-size: 16px;
                line-height: 24px;
                // font-weight: 100;
                margin: 25px 0 10px;
              }

              a {
                font-size: 16px;
                line-height: 30px;
                // font-weight: 100;
                color: inherit;
              }

              @media (max-width: 920px) {
                padding: 30px;
                width: 100%;
              }
            }
          }
        }
      }

      @media print {
        page-break-inside: avoid;
      }
    }

    .animating {
      transition: 0.5s;
    }

    .bullets-container {
      position: absolute;
      bottom: 126px;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;

      .bullet {
        cursor: pointer;
        height: 6px;
        width: 6px;
        border: 1px solid #858485;
        margin: 0 4px;
        border-radius: 5px;
        text-align: center;

        &.active {
          background: #858485;
        }
      }
    }

    .navigation-button {
      display: none;
      cursor: pointer;
      opacity: 0;
      position: absolute;
      width: 65px;
      height: 120px;
      top: 40%;
      background-color: #f7f7f7;
      text-align: center;
      color: #b4b4b5;
      transition: opacity 0.25s ease-in-out;
      font-size: 65px;
      line-height: 120px;
      border-radius: 5px;

      &:hover {
        color: #444446;
      }

      &.left {
        left: 40px;
      }

      &.right {
        right: 40px;
      }
    }

    .ui-button{
      font-size: 16px;
    }
    @media (min-width: 720px) {
      .navigation-button {
        display: block;
      }
    }

  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  // IE10+ styles here
  pinch-zoom {
    .pinch-zoom-content {
      display: block !important;
    }
  }
  page-media-slider {
    .media-slider-wrapper {
      &.full-width {
        max-width: none !important;
        margin: auto !important;
      }
    }
  }
}

//
//
@mixin theme-page($theme) {
  @include page-article-theme($theme);
  @include page-media-slider-theme($theme);
}

@mixin user-theme-favorit-list($theme) {
  user-favorit-list {
    .list {
      .list-item {
        height: 150px;
        padding: 20px 10px;
        border-bottom: 1px solid black;
        display: grid;
        grid-template-columns: minmax(auto, 150px) 1fr 150px;
        > img {
          align-self: center;
        }
        .description {
          display: flex;
          flex-direction: column;
          h3 {
            margin-top: 0;
            margin-bottom: 5px;
            font-size: 16px;
            line-height: 16px;
            font-weight: bold;
          }
          p {
            margin: 0;
            font-size: 13px;
          }
          .inputs {
            margin-top: 20px;
            select, input{
              margin-right: 10px;
              border-radius: 5px;
              width: 100px;
              height: 30px;
            }
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          .buttons {
            font-size: 16px;
            cursor: pointer;
          }
          .prices {
            display: flex;
            width: 100%;
            font-size: 13px;
            margin-bottom: 12px;
            > div {
              flex: 1;
              p {
                text-align: right;
                margin: 0;

              }
            }
          }
        }
      }
    }
  }
}

@mixin user-theme-profile($theme) {
  user-profile{
    .section{
      padding: 20px 10px;
      border-bottom: 1px solid black;
      display: flex;
      .sub-section{
        flex: 1;
        display: flex;
        flex-direction: column;
        p{
          margin: 0;
          font-size: 13px;
          line-height: 18px;
          &:first-child{
            font-weight: bold;
          }
        }
        &:last-child{
          align-items: flex-end;
        }
      }
    }
  }
}

@mixin user-customers-theme($theme) {
  .user-customers-container {
    display: grid;

    .filter-bar {
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: 10px;
      margin-bottom: 4rem;
    }
  }
  .customer {
    padding: 1.6em;
    min-height: 6.8em;
    border-bottom: 1px solid #d9d9d9;
    cursor: pointer;
    color: #353535;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid map-get(map-get($theme, "element"), "dark");

    .title {
      font-size: 16px;
      line-height: 23px;
      font-weight: 700;
    }

    &:first-child {
      border-top: 1px solid map-get(map-get($theme, "element"), "dark");
    }

    &.active {
      .opened {
        display: grid;
        gap: 10px;
        margin-top: 1.6em;

        .left {
          p {
            margin: 0 0 0.7em;
          }

          .show-more {
            color: #106dbe;
            font-size: 14px;
            margin-top: 2em;

            i {
              margin-left: 0.1em;
              font-size: 0.7em;
              margin-top: 0.2em;
            }
          }
        }

        .right {
          margin-top: 10px;

          .clients {
            align-items: center;
            display: flex;

            i {
              font-size: 22px;
              color: #106dbe;
            }

            p {
              font-size: 14px;
              color: #106dbe;
              //margin: 0 0px 1.7em;
              display: flex;
              align-items: center;

              i {
                margin-right: 16px;
                font-size: 18px;
              }
            }
          }
        }
      }

      .closed {
        font-size: 14px;
        display: none;
      }
    }

    .opened {
      display: none;
      @media (min-width: map-get(map-get($theme, "breakpoints"), "phone")) {
        grid-template-columns: 1.35fr 1fr;
      }

      .left {
        align-self: baseline;
        display: grid;
        grid-template-columns: 9.7em 1fr;
      }
    }
  }
}

@mixin user-orders-theme($theme) {
  .user-orders-container {
    display: grid;
    .filter-bar {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 35px;
    }
  }
  .order-category-title {
    width: 100%;
    margin-left: 4px;
    font-size: 24px;
    margin-bottom: 18px;
  }
  $order-line-grid: 40px 80px 1fr 1fr 100px;
  .order-list-header {
    padding: 13px 12px 7px 17px;
    border-radius: 5px 5px 0px 0px;
    display: grid;
    background: #eeedee;
    color: #868485;
    font-size: 14px;
    grid-template-columns: $order-line-grid;
    column-gap: 30px;
    border-bottom: 1px solid map-get(map-get($theme, "element"), "dark");
    .head {
      &:last-child {
        text-align: right;
      }
    }
  }
  .order-group{
    margin-bottom: 60px;

  }
  .order {
    min-height: 60px;
    color: #353535;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid map-get(map-get($theme, "element"), "dark");
    &:first-child {
      border-top: 1px solid map-get(map-get($theme, "element"), "dark");
    }
    .closed {
      grid-template-columns: $order-line-grid;
      padding: 0 8px 0 16px;
      display: grid;
      column-gap: 30px;
      font-size: 14px;
      color: #353535;
      cursor: pointer;
    }
    &.active {
      border: 1px solid map-get(map-get($theme, "element"), "dark");
      border-top: none;
      .opened {
        display: block;
        .action-bar {
          display: grid;
          color: #7a7a7a;
          height: 59px;
          gap: 13px;
          padding: 3px 0px 0 15px;
          align-items: center;
          grid-template-columns: 50px 50px 1fr 50px;
          border-bottom: 1px solid map-get(map-get($theme, "element"), "dark");
          border-top: none;
          i {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
        .order-content {
          padding: 32px 34px 32px 42px;
          .order-number {
            font-size: 18px;
            margin-bottom: 27px;
          }
          .buyer-info {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(100px, 212px));
            gap: 10px;
            margin-bottom: 20px;
            .info-box {
              font-size: 14px;
              > p {
                margin-top: 0.25rem;
                margin-bottom: 0;
              }
              > a {
                display: block;
                margin-top: 2rem;
              }
            }
          }
          .left {
            p {
              margin: 0 0 0.7em;
            }
          }

          .right {
            p {
              font-size: 14px;
              color: #106dbe;
              font-size: 14px;
              color: #106dbe;
              margin: 0 0px 1.7em;
              display: flex;
              align-items: center;

              i {
                margin-right: 16px;
                font-size: 18px;
              }
            }
          }
        }
      }

      .closed {
        display: none;
      }
    }


  }
}

@mixin list-overview-theme($theme) {
  .list-overview-container {
    color: #353535;
    display: grid;
    .filter-bar {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 10px;
      margin-bottom: 2rem;
    }
  }
  .filter-header {
    display: flex;
    .head {
      cursor: pointer;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 18px;
      }
    }
  }
}



@mixin user-create-account-theme($theme) {
  .user-creat-account-container{
    >h1 {
      font-weight: bold;
      color: black;
      font-size: 24px;

      padding: 10px 0;
    }
    .padding{
      border-top: 1px solid #D9D9D9;
    }
    .step-controls{
      padding: 4em 0;
      background: white;
      .tab.active{
        display: flex;
        justify-content: space-between;
        font-size: 16px;
      }
      }
      .form{
        background-color: #F5F5F5;
        padding: 38px 0 0 0;

      }
    .steps{
      display: flex;
      align-items: center;
      .step{
        color: rgba($color: #000000, $alpha: 0.4);
        cursor: pointer;
        color: map-get(map-get($theme, 'text'), 'base');
        padding:1.7rem;
        font-size: 16px;
        &:first-child{
          padding-left: 0;
        }
        &.active{
          color: black;
        }
      }
      .step-line{
        height: 0;
        width: 40px;
        border-top: 1px solid map-get(map-get($theme, 'element'), 'dark');
      }
    }
    .page{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 50px;
    }
    p.success{
      color: map-get($theme, 'success');
      font-weight: 700;
    }
  .done{
    padding: 38px 0 38px 0 !important;
    font-size: 13px;
     > div > * {
       display: block !important;
     }
    .label{
      margin: 30px 0 8px 0;
        color: rgba($color: #000000, $alpha: 0.6)
    }
  }
  }
}

@mixin user-first-password-theme($theme) {
  .user-first-password-container {
    min-width: 400px;
    > h3 {
      font-weight: bold;
      color: black;
      margin-bottom: 10px;
      font-size: 20px;
    }
    .tab{
      margin-top: 20px;
      display: flex;
    }
    p.success {
      color: map-get($theme, "success");
      font-weight: 700;
    }
  }
}

@mixin edit-list-theme($theme) {
  .edit-list-container {
    color: #353535;
    max-width: 80vw;

    height: 100vh;
    .header {
      height: 60px;
      padding: 5px 13px 5px 0;
      display: grid;
      box-shadow: 0px 10px 13px -7px #c9c9c9, 5px 5px 15px 5px rgb(0 0 0 / 0%);
      background: white;
      position: sticky;
      top: 0;
      z-index: 1;
      grid-area: header;
      align-items: center;
      grid-template-columns: auto 1fr auto;
      gap: 20px;
      .back {
        cursor: pointer;
        padding: 0 15px;
        font-size: 20px;
        display: flex;
        height: 80%;
        align-items: center;
        span {
          font-size: 30px;
        }
        border-right: 1px solid black;
      }
      .title {
        font-size: 18px;
        color: #353535;
        font-weight: bold;
      }
      .buttons {
        grid-template-columns: auto auto auto auto auto;
        height: 100%;
        display: grid;
        align-items: center;
        gap: 20px;
        padding-right: 5px;
        > * {
          cursor: pointer;
          font-size: 20px;
        }
        .ui-button {
          font-size: 14px;
        }
      }
    }
    .logo-line {
      grid-area: logo;
      padding: 10px;
      display: grid;
      grid-template-columns: 1fr auto;
      .logo {
        height: 50x;
        grid-column: 2;
      }
    }
    .list-info {
      grid-area: info;
      background: #ececec;
      grid-template-columns: 1fr 1fr;
      gap: 50px;
      padding: 2rem 50px 3.3rem;
      display: grid;
      .info-box {
        font-size: 14px;
        .img {
          max-width: 100%;
        }
        > p {
          margin-top: 0.25rem;
          margin-bottom: 0;
        }
        > a {
          display: block;
          margin-top: 2rem;
        }
        .ui-button {
          margin-top: 5px;
          display: block;
          border: 1px solid black;
        }
      }
    }
    .list-items-container {
      grid-area: list;
      margin-bottom: 50px;
      .list-items-extra {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 10px;
        padding: 2rem 50px;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        .ui-input {
          border: none;
          padding: 5px;
          &.title {
            font-size: 24px;
            color: #353535;
            font-weight: bold;
          }
        }
        .date {
          height: 30px;
          display: flex;
          align-items: center;
          span {
            font-size: 18px;
            color: #353535;
          }
        }
      }
      .list-items-header {
        padding: 0 65px;
        flex-direction: row;
        display: flex;
        gap: 10px;
        border-bottom: 1px solid #d9d9d9;
        .head {
          justify-content: flex-end;
          word-break: break-all;
          display: flex;
          align-items: flex-end;
          font-weight: bold;
          flex: 0 0 80px;
        }
      }
      textarea {
        border: none;
        overflow: hidden;
        padding: 0;
        box-sizing: content-box;

        &:focus {
          outline: none;
        }
      }
    }
    .buttons-container {
      align-self: baseline;
      grid-area: button;
      position: sticky;
      bottom: 20px;
      padding: 20px 50px;
      display: flex;
      gap: 20px;

      font-size: 14px;
      margin-bottom: 20px;
    }
  }
  .edit-list-item {
    display: grid;

    border-bottom: 1px solid #d9d9d9;
    page-break-inside: avoid;
    background: white;
    grid-template-columns: 50px 1fr 50px;
    .left {
	    transition: all 0.2s ease-in-out;
      cursor: pointer;
      height: 100%;
      justify-content: center;
      border-right: 1px solid #d9d9d9;
      color: #d9d9d9;
      display: flex;
      align-items: center;
    }
    .middle {
      padding: 1rem;
    }
    .right {
      cursor: pointer;
      height: 100%;
      border-left: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #d9d9d9;
    }

    &:last-of-type {
      border-bottom: 1px solid #d9d9d9;
    }
    &.title {
      .middle {
        padding: 3rem 2.5rem;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        input {
          outline: none;
          border: none;
        }
        .text {
          width: 100%;
          font-size: 20px;
          font-weight: bold;
        }
        .description {
          font-size: 14px;
        }
      }
    }
    &.last-active{
	    .left{
		    background: var(--primary-color);
		    color: white;
	    }
    }
    &.product {
      .middle {
        align-items: center;
        display: grid;
        gap: 10px;
        grid-template-columns: 100px 1fr auto;
        img {
          max-width: 100%;
        }
        .text {
          b {
            font-size: 14px;
          }
          .description {
            margin-top: 12px;
            > p {
              margin: 0;
            }
          }
          input {
            outline: none;
            font-size: 12px;
            color: #353535;
            border: none;
          }
        }
        .values {
          display: flex;
          height: 100%;
          padding-left: 10px;
          gap: 10px;
          .value {
            width: 80px;
            align-self: baseline;
            justify-content: flex-end;
            align-items: center;
            display: flex;
            //select input that is not a type of checkbox
            .ui-input[type="checkbox"] {
              flex: 0 0 13px;
              width: auto;
            }
          }
        }
      }
    }
    &.add-product {
      .middle {
        padding: 3rem 3rem;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        .ui-input {
          max-width: 300px;
        }
      }
    }
    &.cdk-drag-preview {
      box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
      opacity: 0.5;
    }
  }

  @media print {
    .edit-list-container {
      min-width: 100vw !important;
      ::placeholder {
        color: rgb(0 0 0 / 0%);
      }
      input {
        border: none !important;
      }

      .list-items-header {
        padding: 1.2rem !important;
      }
      width: 100vw;
      .value {
        width: 50px;
      }
      .head {
        flex: 0 0 50px;
      }
      .edit-list-item {
        position: relative;
        grid-template-columns: 1fr;
        .values {
          input {
            text-align: right;
            padding: 0;
          }
        }
        .left,
        .right {
          display: none;
          opacity: 0;
        }
      }
      .buttons-container {
        display: none;
      }
      .header {
        display: none;
      }
    }
  }
}

@mixin basket-theme($theme) {
  .basket-page {
    .basket-search {
      position: relative;
      flex: 1;
      input {
        width: 100%;
      }
    }
    .basket-search-spinner {
      position: absolute;
      right: 10px;
      top: 10px;
    }
    .header {
      position: relative;
      padding: 2.3rem 0 1.6rem 0;
      width: 100%;

      > a {
        position: absolute;
        right: 0;
        top: 29px;
        font-size: 14px;
        text-decoration: none;
      }

      h4 {
        font-size: 16px;
        font-weight: bold;
        color: #313131;
        margin: 0;
        margin-bottom: 0.4rem;
      }

      h3 {
        font-size: 24px;
        font-weight: bold;
        color: #313131;
      }
    }

    .buyer-info {
      background: #ececec;

      .project-content-margin {
        padding: 2rem 0 3.3rem;
        display: flex;

        .info-box {
          padding: 0 12.4rem 0 0;
          font-size: 14px;

          > b {
            position: relative;

            > a {
              position: absolute;
              top: -5px;
              left: calc(100% + 5px);
            }
          }

          > p {
            margin-top: 0.25rem;
            margin-bottom: 0;
          }

          > a {
            display: block;
            margin-top: 2rem;
          }
        }
      }
    }

    .basket {
      .input {
        padding: 3rem 0 1.6rem;
        display: flex;
        align-items: center;

        .search {
        }

        .amount {
          flex: 0 0 50px;
        }
      }

      .list-action {
        margin-bottom: 15px;
        display: flex;
        div {
          margin-right: 40px;
          padding: 5px;
          font-size: 14px;
          cursor: pointer;
        }
      }

      .price {
        display: flex;
        justify-content: flex-end;
        .content {
          margin-top: 30px;
          flex: 0 0 64%;
          .accept-payment {
            display: flex;
            margin-top: 30px;
            margin-bottom: 30px;
            flex-direction: column;
            .final-details {
              display: grid;
              grid-template-columns: 1fr 2fr 2fr;
              column-gap: 5px;
              background-color: #eeedee;
              border-radius: 7px;
              padding: 10px;
              margin-bottom: 20px;
              .checkbox {
                padding: 5px 0;
                font-size: 14px;
                display: flex;
                align-items: center;
                input {
                  margin-right: 5px;
                }
              }
            }

            button {
              font-size: 16px;
            }
          }
        }
      }
    }
    a {
      text-decoration: none;
    }
    @media print {
      .header {
        background-image: url("/assets/logo.png");
        background-position: left center;
        background-size: contain;
        background-repeat: no-repeat;
        margin-bottom: 10px;

        > * {
          display: none;
        }
      }
      .info-box {
        padding: 0 6rem 0 0 !important;
        a {
          display: none !important;
        }
      }
      .basket {
        .amount {
          display: flex;
          justify-content: center;
          align-items: center;

          .ui-input {
            border: 0px solid white !important;
          }
        }
        .basket-list > div > i {
          display: none;
        }

        .basket-list :first-child {
          border-top: none !important;
        }

        .basket-item {
          grid-template-columns: 149px 1fr 83px 90px !important;
        }

        .input {
          display: none;
        }

        .accept-payment {
          button {
            display: none !important;
          }
        }
      }
    }
  }
}

@mixin user-info-card-theme($theme) {
}





@mixin theme-user($theme) {
  @include user-theme-favorit-list($theme);
  @include user-theme-profile($theme);
  @include user-customers-theme($theme);
  @include user-orders-theme($theme);
  @include user-create-account-theme($theme);
  @include user-first-password-theme($theme);
  @include list-overview-theme($theme);
  @include edit-list-theme($theme);
  @include basket-theme($theme);
  @include user-info-card-theme($theme);
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp");

@include theme-basket(variables.$theme);
@include theme-webshop(variables.$theme);
@include theme-ui(variables.$theme);
@include theme-page(variables.$theme);
@include theme-user(variables.$theme);

html,
body {
  font-family: "Open Sans";
  margin: 0;
  font-size: var(--default-font-size);
  margin: auto;
}

h1 {
  color: var(--primary-color);
  font-size: 35px;
  line-height: 50px;
  font-weight: 100;
  margin: 0;
  word-break: keep-all;
}

h2 {
  color: var(--primary-color);
  font-size: 35px;
  line-height: 40px;
  font-weight: 100;
  margin: 0;
}
h3 {
  color: var(--primary-color);
  font-size: 25px;
  line-height: 35px;
  font-weight: 100;
  margin: 0;
}
p {
  font-weight: inherit;
}
a {
  color: var(--primary-color);
}
