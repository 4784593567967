@mixin header-footer-theme($theme) {
  app-header-and-footer {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    > .content {
      flex: 1;
      margin-top: 2rem;
      margin-bottom: 4rem;
      //padding: 10px 50px;
      //max-width: 1920px;
      @media (max-width: map-get(map-get($theme, "breakpoints"), "phone")) {
      }
    }

    .topbar {
      margin-bottom: 30px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      ui-breadcrumb {
        flex: 3;
      }

      .search-container {
        flex: 0 0 33%;
      }
    }

    .floating-button {
      position: fixed;
      bottom: 40px;
      right: 20px;
      background-color: #003b6e;
      border-radius: 100%;
      border: none;
      font-size: 16px;
      font-family: inherit;
      color: #ffffff;
      display: none;
      cursor: pointer;
      height: 48px;
      width: 48px;
      box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.5);

      &:focus {
        outline: none;
      }
    }

    @media (max-width: map-get(map-get($theme, "breakpoints"), "phone")) {
      .topbar {
        flex-direction: column;
        align-items: flex-start;
        ui-breadcrumb {
          flex: 1;
          margin-bottom: 10px;
        }

        .search-container {
          flex: 1;
          width: 100%;
        }

        margin-bottom: 20px;
      }
    }
  }
  html:not([data-scroll="111110"]) {
    .floating-button {
      display: block;
    }
  }
}

@media print {
  /* invert the display (show/hide) properties of the product */
  /* application component and the template component       */
  .topbar {
    display: none !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  // IE10+ styles here
  app-header-and-footer {
    display: block !important;
  }
}

:host {
  .main-navigation {
    background-color: var(--primary-color);

    @media (max-width: 1024px) {
      display: none;
    }

    sm-navigation {
      display: flex;
      gap: 32px;
      flex-wrap: wrap;

      ::ng-deep {
        > .navigation__item {
          > .navigation__link {
            line-height: 35px;
            color: #ffffffbf;
            text-decoration: none;
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            padding-inline: 20px;
            transition: color 150ms linear;
            display: block;

            &:hover {
              color: #fff;
            }
          }

          &:first-child {
            > .navigation__link {
              padding-left: 0;
            }
            .navigation__inner__items {
              left: 0;
            }
          }

          &:last-child {
            > .navigation__link {
              padding-right: 0;
            }
            .navigation__inner__items {
              right: 0;
              left: auto;
            }
          }
        }
        .navigation__inner__items {
          display: none;
          position: absolute;
          background: #fff;
          border-radius: 5px;
          border: 1px solid #DCDCDC;
          box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.21);
          padding-block: 10px;
          z-index: 1;
          top: calc(100% + 6px);
          left: 20px;

          // So the dropdown doesn't hide if on hover on gap
          &::before {
            content: '';
            position: absolute;
            bottom: 100%;
            height: 8px;
            z-index: 9;
            left: 0;
            right: 0;
          }

          .navigation__link {
            padding-inline: 27px;
            line-height: 30px;
            color: #676767;
            text-decoration: none;
            font-size: 14px;
            display: block;
            transition: background-color 150ms linear;

            &:hover {
              background-color: #DCDCDC;
              color: #676767;
            }
          }
        }
        .navigation__item {
          position: relative;

          &:hover {
            > .navigation__inner__items {
              display: block;
            }
          }
        }
        .navigation__link {
          white-space: nowrap;
        }
      }
    }
  }
}
