@font-face {
  font-family: 'FLE-icons';
  src:
    url('fonts/FLE-icons.woff2?4k1y6k') format('woff2'),
    url('fonts/FLE-icons.ttf?4k1y6k') format('truetype'),
    url('fonts/FLE-icons.woff?4k1y6k') format('woff'),
    url('fonts/FLE-icons.svg?4k1y6k#FLE-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'FLE-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-basket:before {
  content: "\e609";
}
.icon-shop:before {
  content: "\e639";
}
.icon-arrowgotobasket:before {
  content: "\e643";
}
.icon-arrowputinbasket:before {
  content: "\e644";
}
.icon-arrowuplonglight:before {
  content: "\e642";
}
.icon-arrow-short-back:before {
  content: "\e600";
}
.icon-arrowdown:before {
  content: "\e601";
}
.icon-arrowleft:before {
  content: "\e602";
}
.icon-arrowleftlight:before {
  content: "\e603";
}
.icon-arrowleftlonglight:before {
  content: "\e604";
}
.icon-arrowright:before {
  content: "\e605";
}
.icon-arrowrightlight:before {
  content: "\e606";
}
.icon-arrowrightlonglight:before {
  content: "\e607";
}
.icon-arrowup:before {
  content: "\e608";
}
.icon-catalog:before {
  content: "\e60a";
}
.icon-circle-bg:before {
  content: "\e60b";
}
.icon-circle:before {
  content: "\e60c";
}
.icon-deduct:before {
  content: "\e60d";
}
.icon-deductcircle:before {
  content: "\e60e";
}
.icon-delete-30px:before {
  content: "\e60f";
}
.icon-doc13:before {
  content: "\e610";
}
.icon-dropdown:before {
  content: "\e611";
}
.icon-edit-nostroke:before {
  content: "\e612";
}
.icon-edit:before {
  content: "\e613";
}
.icon-facebook:before {
  content: "\e614";
}
.icon-filter:before {
  content: "\e615";
}
.icon-googleplus:before {
  content: "\e616";
}
.icon-instructions:before {
  content: "\e617";
}
.icon-lightbox:before {
  content: "\e618";
}
.icon-linkedin:before {
  content: "\e619";
}
.icon-list1:before {
  content: "\e61a";
}
.icon-magnifyingglass:before {
  content: "\e61b";
}
.icon-mail:before {
  content: "\e61c";
}
.icon-mailpage:before {
  content: "\e61d";
}
.icon-mapposition:before {
  content: "\e61e";
}
.icon-menu:before {
  content: "\e61f";
}
.icon-multiply:before {
  content: "\e620";
}
.icon-multiplycircle:before {
  content: "\e621";
}
.icon-multiplylight:before {
  content: "\e622";
}
.icon-on:before {
  content: "\e623";
}
.icon-openinnewwindow13:before {
  content: "\e624";
}
.icon-openinnewwindow16:before {
  content: "\e625";
}
.icon-phone:before {
  content: "\e627";
}
.icon-play:before {
  content: "\e628";
}
.icon-plus:before {
  content: "\e629";
}
.icon-pluscircle:before {
  content: "\e62a";
}
.icon-pluslight:before {
  content: "\e62b";
}
.icon-print:before {
  content: "\e62c";
}
.icon-profile-contact:before {
  content: "\e62d";
}
.icon-profile-dashboard:before {
  content: "\e62e";
}
.icon-profile-docs:before {
  content: "\e62f";
}
.icon-profile-favlist:before {
  content: "\e630";
}
.icon-profile-info:before {
  content: "\e631";
}
.icon-profile-logout:before {
  content: "\e632";
}
.icon-profile-mappostion:before {
  content: "\e633";
}
.icon-profile-openbasket:before {
  content: "\e634";
}
.icon-profile-user:before {
  content: "\e635";
}
.icon-profile:before {
  content: "\e636";
}
.icon-reset-circle:before {
  content: "\e637";
}
.icon-share:before {
  content: "\e638";
}
.icon-slideleft:before {
  content: "\e63a";
}
.icon-slideright:before {
  content: "\e63b";
}
.icon-star-outline:before {
  content: "\e63c";
}
.icon-star:before {
  content: "\e63d";
}
.icon-tick:before {
  content: "\e63e";
}
.icon-tickbold:before {
  content: "\e63f";
}
.icon-tiles:before {
  content: "\e640";
}
.icon-twitter:before {
  content: "\e641";
}
.icon-Aabningstider:before {
  content: "\e900";
}
.icon-Automatik:before {
  content: "\e901";
}
.icon-Dag_t_dag_levering:before {
  content: "\e902";
}
.icon-E-mail:before {
  content: "\e903";
}
.icon-Faa_besoeg_af_produktkonsulenter:before {
  content: "\e904";
}
.icon-Fragtskader-og-efterlysning:before {
  content: "\e905";
}
.icon-Galleri_blaa_aktiveret:before {
  content: "\e906";
}
.icon-Galleri_graa:before {
  content: "\e907";
}
.icon-Handelsbetingelser:before {
  content: "\e908";
}
.icon-Jobkarriere:before {
  content: "\e909";
}
.icon-Kompetente_medarbejdere:before {
  content: "\e90a";
}
.icon-Kontakt_osc:before {
  content: "\e90b";
}
.icon-customer_support:before {
  content: "\e90c";
}
.icon-list:before {
  content: "\e90d";
}
.icon-Liste_graa:before {
  content: "\e90e";
}
.icon-fle-star:before {
  content: "\e90f";
}
.icon-fle_drill:before {
  content: "\e910";
}
.icon-Print:before {
  content: "\e911";
}
.icon-Produktkonsulenter:before {
  content: "\e912";
}
.icon-Returnering:before {
  content: "\e913";
}
.icon-Service-og-reparation:before {
  content: "\e914";
}
.icon-Soeg:before {
  content: "\e915";
}
.icon-Spoergsmaalsvar:before {
  content: "\e916";
}
.icon-Stjerne:before {
  content: "\e917";
}
.icon-fle_stort_udvalg:before {
  content: "\e918";
}
