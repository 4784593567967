@mixin pagesOverride($theme) {
  section[class*="page"]:not(.page-section) {
    margin-top: 60px;

    &.page-mediagallery {
      .caption {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        @media (min-width: map-get(map-get($theme, "breakpoints"), "phone")) {
          height: 310px !important;
        }
      }
    }
    &.page-stackAccordion {
      page-article {
        .project-content-margin {
          padding: 0;
          margin: 0;
        }
        .image {
          img {
            max-width: 100%;
          }
        }
      }
    }

    // If new teaserstack2 is first child in stack, do not add 60px margin,
    //  Because teaserstack2 itself has its padding-top
    &:first-child {
      margin-top: 0;
    }

    // Because teaserstack2 has padding-top and padding-bottom, we need to do this math to make sure spacing is 60px
    &.page-teaserstack2:not(:first-child),
    &.page-newteaserstack2:not(:first-child) {
      margin-top: calc(60px - var(--content-margin) * 2);
      margin-bottom: calc(var(--content-margin) * -2);
    }
  }

  page-teaser-stack {
    h4 {
      font-size: 18px;
      margin-bottom: 15px;
    }

    .teaser-stack {
      &.project-content-margin {
        .teaser-item {
          margin-bottom: 20px;
        }
      }
      .teaser-item {
        @media (min-width: map-get(map-get($theme, "breakpoints"), "phone")) {
          min-height: 320px;
        }
      }
    }
  }
}
