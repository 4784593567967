@mixin footer-theme($theme) {
  app-footer {
    .max-width {
      max-width: 1200px;
      background: map-get(map-get($theme, "element"), "light");
    }

    .blue-bar {
      height: 135px;
      background: map-get($theme, "primary");
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 25px;
      color: white;

      .number {
        color: white;
        padding: 0 10px 0 5px;
        text-decoration: none;
      }

      a {
        padding: 0 5px 0 10px;
        color: white;;
      }
    }

    .full-width {
      background: lighten($color: #000000, $amount: 95%)
    }

    .link-container {
      margin: auto;
      padding: 40px 20px;
      display: flex;
      max-width: 1200px;
      justify-content: center;

      .link-column {
        flex: 1;

        .item {
          color: map-get(map-get($theme, "text"), "dark");
          line-height: 34px;
          font-size: 16px;
        }
        .link {
          color: map-get(map-get($theme, "text"), "dark");
          line-height: 34px;
          font-size: 16px;
          text-decoration: none;
          display: block;

          &:hover {
            cursor: pointer;
            color: map-get($theme, "primary");
            font-weight: 400 !important;
          }
        }
      }
    }

    .copyright {
      height: 60px;
      background: lighten($color: #000000, $amount: 85%);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: map-get(map-get($theme, "text"), "dark");

      a {
        padding-left: 5px;
        cursor: pointer;
        color: map-get($theme, "primary");
        text-decoration: underline;
      }
    }

    @media (max-width: map-get(map-get($theme, "breakpoints"), "phone")) {
      .blue-bar {
        height: unset;
        flex-direction: column;
        padding: 30px 0;

        .number {
          margin-bottom: 30px;
        }
      }
      .link-container {
        flex-wrap: wrap;

        .link-column {
          flex-direction: column;
          flex: unset;
          width: 100%;
          display: flex;
          justify-content: center;
          margin-bottom: 20px;

          .link {
          }
        }
      }
    }
  }
}
