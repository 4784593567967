@use 'variables';
@import "../projects/ngx-sitemule/dist/ngx-sitemule/theme.scss";
@import "./app/components/footer/footer.component.scss";
@import "./app/components/header-and-footer/header-and-footer.component.scss";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap");
@import "./styleOverrides/icons.scss";
@import "./styleOverrides/pages.scss";

@include footer-theme(variables.$theme);
@include header-footer-theme(variables.$theme);

@include pagesOverride(variables.$theme);

html,
body {
  font-family: "Open Sans";
  margin: 0;
  font-size: 12px;
  margin: auto;
}

h1 {
  color: var(--primary-color);
  font-size: 35px;
  line-height: 50px;
  font-weight: 100;
  margin: 0;
  word-break: keep-all;
}

h2 {
  color: var(--primary-color);
  font-size: 35px;
  line-height: 40px;
  font-weight: 100;
  margin: 0;
}
h3 {
  color: var(--primary-color);
  font-size: 25px;
  line-height: 35px;
  font-weight: 100;
  margin: 0;
}
p {
  font-weight: inherit;
}
a {
  color: var(--primary-color);
}

input:focus,
textarea:focus,
select:focus,
* {
}
